type Params = {
  limitReached: boolean;
  dailyLimit: number;
  currentLimit: number;
  currentDonutsCount: number;
};

export const calculateEarnedCurrency = ({
  limitReached,
  dailyLimit,
  currentLimit,
  currentDonutsCount,
}: Params) => {
  if (limitReached) {
    return dailyLimit - currentLimit;
  } else {
    return currentDonutsCount;
  }
};
