import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DONUT_TYPES,
  DONUT_SIZES,
  DEFAULT_FINGER_SLIDER_CONFIG,
  DONUT_AWARD_VALUES,
} from 'shared/constants';
import { getRandomBoolean, getRandomValueWithChance, randomNumberBetween } from 'shared/utils';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DonutEntity, DonutSize } from 'shared/types';
import { v4 } from 'uuid';

type useDonutsProps = {
  stopGame: (limitReached?: boolean) => Promise<void>;
};

export const useDonuts = ({ stopGame }: useDonutsProps) => {
  const [donuts, setDonuts] = useState<DonutEntity[]>([]);
  const gameActive = useGameStore((state) => state.gameActive);
  const currentDonutsCount = useGameStore((state) => state.currentDonutsCount);
  const increaseDonutsCount = useGameStore((state) => state.increaseDonutsCount);
  const settings = useGameStore((state) => state.settings);
  const increaseFailedDonuts = useGameStore((state) => state.increaseFailedDonuts);
  const currentDonutFallSpeed = useGameStore((state) => state.currentDonutFallSpeed);
  const user = useGlobalStore((state) => state.user);
  const appVisible = useGlobalStore((state) => state.appVisible);
  const setGameActive = useGameStore((state) => state.setGameActive);

  const requiredMinDonuts = useRef(25);
  const userFingerSize = parseInt(user.finger_size || '') || DEFAULT_FINGER_SLIDER_CONFIG.min;

  const deleteDonut = (donutId: string) => {
    setDonuts((prev) => prev.filter((donutItem) => donutItem.id !== donutId));
  };

  const handleDonutClick = useCallback(
    (donut: DonutEntity) => {
      if (!gameActive) return null;

      if (userFingerSize === donut.size) {
        increaseDonutsCount(donut.value);
        return true;
      }

      increaseFailedDonuts(1);
      return false;
    },
    [userFingerSize, gameActive],
  );

  const handleDonutAnimationEnd = useCallback((donutId: string) => {
    deleteDonut(donutId);
  }, []);

  const addDonut = useCallback(() => {
    const id = v4();
    const type = DONUT_TYPES[randomNumberBetween(0, DONUT_TYPES.length - 1)];
    const positionX = randomNumberBetween(0, window.innerWidth - 100);
    let size = DONUT_SIZES[randomNumberBetween(0, DONUT_SIZES.length - 1)];
    const value = getRandomValueWithChance(DONUT_AWARD_VALUES);

    if (getRandomBoolean() && getRandomBoolean() && requiredMinDonuts.current > 0) {
      size = userFingerSize as DonutSize;
      requiredMinDonuts.current -= 1;
    }

    setDonuts((prev) => [
      ...prev,
      { id, type, positionX, size, value, fallDuration: currentDonutFallSpeed },
    ]);
  }, [userFingerSize, currentDonutFallSpeed]);

  useEffect(() => {
    const finishgame = async () => {
      if (
        Math.floor(user.current_limit + currentDonutsCount) >=
        Math.floor(user.daily_limit + user.daily_bonus_limit)
      ) {
        setGameActive(false);

        await stopGame(true);
      }
    };
    finishgame();
  }, [currentDonutsCount, user.daily_limit, user.daily_bonus_limit]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!gameActive || !appVisible) {
        clearInterval(interval);
        setDonuts([]);
        return;
      }

      addDonut();
    }, settings.fall_donuts_interval * 1000);

    return () => clearInterval(interval);
  }, [gameActive, appVisible, addDonut]);

  return {
    donuts,
    handleDonutClick,
    handleDonutAnimationEnd,
  };
};
