import styles from './style.module.scss';
import { memo, useState } from 'react';
import { m, useAnimate } from 'framer-motion';
import { DONUT_INITIAL_Y } from 'shared/constants';
import { UITypography } from 'shared/ui';
import { DonutEntity } from 'shared/types';
import { getRandomBoolean } from 'shared/utils';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { StarsDonut } from 'components/lottie';

export type DonutProps = DonutEntity & {
  onClick: (donut: DonutEntity) => boolean | null;
  onAnimationComplete: (id: string) => void;
};

export const Donut = memo(
  ({
    size,
    type,
    onClick,
    id,
    onAnimationComplete,
    positionX,
    value,
    fallDuration,
  }: DonutProps) => {
    const [award, setAward] = useState<null | number>(null);
    const [scope, animate] = useAnimate();
    const [impactOccurred] = useHapticFeedback();

    const onDonutClick = () => {
      const correctDonut = onClick({
        id,
        size,
        positionX,
        type,
        value,
        fallDuration,
      });
      if (typeof correctDonut !== 'boolean') return;
      if (scope.current) scope.current.style.pointerEvents = 'none';

      if (correctDonut) {
        setAward(value);

        if (!document.getElementById('donut-image') || !scope.current) return;
        animate('#donut-image', { opacity: 0 }, { duration: 0.1 }).then(() => {
          if (!document.getElementById('award-element') || !scope.current) return;
          animate('#award-element', { scale: 1.2, y: -130, opacity: [1, 0] }, { duration: 0.5 });
        });

        return;
      }

      if (!document.getElementById('donut-image') || !scope.current) return;
      impactOccurred('soft');
      const leftDirection = getRandomBoolean();

      if (!document.getElementById('donut-image') || !scope.current) return;
      animate(
        '#donut-image',
        { rotate: leftDirection ? -360 : 360 },
        { duration: 2, type: 'tween' },
      );
      if (!document.getElementById('donut-image') || !scope.current) return;
      animate(
        '#donut-image',
        {
          y: [0, -15, -25, -30, 0],
          x: leftDirection ? [0, 0, -10, -20, -30] : [0, 0, 10, 20, 30],
        },
        { duration: 0.15, type: 'tween' },
      ).then(() => {
        if (!document.getElementById('donut-image') || !scope.current) return;
        animate(
          '#donut-image',
          {
            y: window.innerHeight,
          },
          {
            duration: 0.4,
            type: 'tween',
          },
        );
      });
    };

    return (
      <m.div
        className={styles['donut-container']}
        onAnimationComplete={() => onAnimationComplete(id)}
        ref={scope}
        onTouchStart={onDonutClick}
        style={{
          left: positionX,
        }}
        initial={{ y: DONUT_INITIAL_Y }}
        animate={{ y: window.innerHeight }}
        transition={{ duration: fallDuration, ease: 'linear', delay: 0.2 }}
      >
        <div className={styles.donutOuterContainer}>
          <m.div id="donut-image" className={styles['donut-inner-container']}>
            <img src={`/donuts/${type}-donut-${size}.png`} alt={''} className={styles.donut} />

            <div className={styles.donutCenter} style={{ width: size, height: size }}></div>
            <div className={styles.stars}>
              <StarsDonut />
            </div>
          </m.div>

          <UITypography variant="Award" className={`${styles.award} `} id="award-element">
            {`+${award}`}
          </UITypography>
        </div>
      </m.div>
    );
  },
);
