import classNames from 'classnames';
import { CSSProperties, ButtonHTMLAttributes, ReactNode, MouseEventHandler } from 'react';
import styles from './style.module.scss';
import { TypographyVariant, UITypography } from '../typography/typography';
import { Spinner } from 'components/loading/spinner';

type ButtonVariant = 'primary' | 'secondary' | 'text';
type ButtonSize = 'small1' | 'small2' | 'default';
type CommonButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
type Props = {
  children?: ReactNode | string | number;
  variant?: ButtonVariant;
  fullwidth?: boolean;
  size?: ButtonSize;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
  fontVariant?: TypographyVariant;
  loading?: boolean;
};

export const UIButton = ({
  children,
  className,
  variant = 'primary',
  fullwidth,
  size = 'default',
  fontVariant = 'Button1',
  loading,
  onClick,
  ...props
}: Props & CommonButtonProps) => {
  const mergedClasName = classNames(
    styles[variant] || '',
    fullwidth ? styles.fullwidth : '',
    styles[size] || '',
    className,
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) return;
    onClick?.(e);
  };

  return (
    <button className={mergedClasName} onClick={handleClick} {...props}>
      {loading ? (
        <Spinner borderWidth={2} size={16} color="inherit" />
      ) : (
        <UITypography variant={fontVariant}>{children}</UITypography>
      )}
    </button>
  );
};
