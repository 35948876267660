import { useRef, useCallback, useEffect, useState } from 'react';
import { getCurrentTimeUTC } from 'shared/api';
import { useGameStore, useGlobalStore } from 'shared/store';

type InitCooldownParams = {
  lastStart: string;
  waitingTime: number;
  _isDayCooldown: boolean;
};

export const useGameCooldownTimer = () => {
  const settings = useGameStore((state) => state.settings);
  const setGameReady = useGameStore((state) => state.setGameReady);
  const gameReady = useGameStore((state) => state.gameReady);
  const gameActive = useGameStore((state) => state.gameActive);
  const user = useGlobalStore((state) => state.user);
  const updateUserStore = useGlobalStore((state) => state.updateUser);

  const [gameCooldown, setGameCooldown] = useState<number | null>(null);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const isDayCooldown = user.current_limit >= user.daily_limit + user.daily_bonus_limit;

  const initCooldown = useCallback(
    async ({ lastStart, waitingTime, _isDayCooldown }: InitCooldownParams) => {
      const { currentTimeUTC, currentTimestampUTC } = await getCurrentTimeUTC();
      const lastStartTimestampUTC = new Date(lastStart).getTime();
      const midnightUTC = new Date(currentTimeUTC).setHours(24, 0, 0, 0);
      const midnightTimestampUTC = new Date(midnightUTC).getTime();
      const defaultCooldown = lastStartTimestampUTC + waitingTime * 1000 - currentTimestampUTC;

      if (_isDayCooldown) {
        setGameCooldown(Math.floor((midnightTimestampUTC - currentTimestampUTC) / 1000));
      } else if (defaultCooldown > 0) {
        setGameCooldown(Math.floor(defaultCooldown / 1000));
      } else {
        setGameCooldown(0);
      }
    },
    [setGameCooldown],
  );

  useEffect(() => {
    if (typeof gameCooldown === 'number' && gameCooldown < 1) {
      interval.current && clearInterval(interval.current);
      setGameCooldown(0);
      setGameReady(true);
      isDayCooldown && updateUserStore({ current_limit: 0 });
    }
  }, [setGameReady, updateUserStore, isDayCooldown, gameCooldown]);

  useEffect(() => {
    const effect = async () => {
      try {
        if (gameReady || gameActive) {
          interval.current && clearInterval(interval.current);
          return;
        }

        await initCooldown({
          lastStart: user.last_start,
          waitingTime: settings.waiting_time,
          _isDayCooldown: isDayCooldown,
        });

        interval.current = setInterval(() => {
          setGameCooldown((prevCooldown) => {
            return prevCooldown ? prevCooldown - 1 : 0;
          });
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    };
    effect();

    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, [initCooldown, gameReady, gameActive, user.last_start, settings.waiting_time, isDayCooldown]);

  return { gameCooldown };
};
