import { useEffect, useRef, useState } from 'react';
import { useGameStore } from 'shared/store';
import { getValuesForTimer } from 'shared/utils';

type useGameTimerProps = {
  stopGame: () => Promise<void>;
  startValue: number;
};

export const useGameTimer = ({ stopGame, startValue }: useGameTimerProps) => {
  const [seconds, setSeconds] = useState(startValue);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeotRef = useRef<NodeJS.Timeout | null>(null);

  const settings = useGameStore((state) => state.settings);
  const increaseCurrentDonutFallSpeed = useGameStore(
    (state) => state.increaseCurrentDonutFallSpeed,
  );
  const setCurrentDonutFallSpeed = useGameStore((state) => state.setCurrentDonutFallSpeed);
  const increaseDonutsFallSpeedInterval = useGameStore(
    (state) => state.increaseDonutsFallSpeedInterval,
  );
  const setGameActive = useGameStore((state) => state.setGameActive);

  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      setSeconds((prev) => prev - 1);
    }, 1000);

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    const handleTimer = async () => {
      if (seconds < 1) {
        setGameActive(false);
        timeotRef.current && clearTimeout(timeotRef.current);

        timeotRef.current = setTimeout(async () => await stopGame(), 500);
        intervalRef.current && clearInterval(intervalRef.current);
        setCurrentDonutFallSpeed(settings.fall_donuts_speed);
        return;
      }

      if (seconds !== startValue && seconds % increaseDonutsFallSpeedInterval === 0) {
        increaseCurrentDonutFallSpeed(0.07);
      }
    };
    handleTimer();
  }, [seconds, stopGame]);

  return { gameTime: getValuesForTimer(seconds), seconds };
};
