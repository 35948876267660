import Lottie from 'react-lottie';
import animationData from 'assets/lottie/stars-main.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const StarsMain = () => {
  return <Lottie options={defaultOptions} height={97} width={'100%'} />;
};
