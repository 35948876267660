import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { UIButton, UITypography } from 'shared/ui';
import { useGlobalStore, useReferralsStore } from 'shared/store';
import { LEAGUE_VARIANTS, GAME_COIN_SHORTCUT } from 'shared/constants';
import { NextLeagueConditionsModal, SuccessEnterLeagueModal } from 'components';
import { useState } from 'react';
import { League } from './league';
import { getLeagueInfo, updateLeagueInfo } from 'shared/api';
import { formatLargeNumber } from 'shared/utils';
import { ReactComponent as Ranking } from 'assets/icons/ranking-2.svg';

export const CurrentLeague = () => {
  const { t } = useTranslation();
  const [sucessModalOpen, setSucessModalOpen] = useState(false);
  const [nextLeagueModalOpen, setNextLeagueModalOpen] = useState(false);
  const user = useGlobalStore((state) => state.user);
  const updateUser = useGlobalStore((state) => state.updateUser);
  const reduceUserBalance = useGlobalStore((state) => state.reduceUserBalance);
  const leagueInfo = useReferralsStore((state) => state.leagueInfo);
  const setLeagueInfo = useReferralsStore((state) => state.setLeagueInfo);
  const [loading, setLoading] = useState(false);

  const onUnlockButtonClick = async () => {
    try {
      setLoading(true);
      const res = await getLeagueInfo();
      setLeagueInfo(res.data);
      setNextLeagueModalOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTransferNextLeague = async () => {
    try {
      const { data } = await updateLeagueInfo();
      updateUser({ league: leagueInfo.kind });
      reduceUserBalance(leagueInfo.price);
      setLeagueInfo({
        kind: data?.kind || 'bronze',
        price: data?.price || 0,
        requirements: data?.requirements || [],
        title: data?.title || '',
      });
      setNextLeagueModalOpen(false);
      setSucessModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.root}>
      <UITypography iconLeft={<Ranking />}>{t('friends.actual_league')}</UITypography>

      <League
        title={
          user.league
            ? t(`${LEAGUE_VARIANTS[user.league].name}`)
            : t(`${LEAGUE_VARIANTS.bronze.name}`)
        }
        img={user.league ? LEAGUE_VARIANTS[user.league].icon : LEAGUE_VARIANTS.bronze.icon}
      />

      {user.league !== 'diamond' && (
        <UIButton fullwidth onClick={onUnlockButtonClick} disabled={!user.league} loading={loading}>
          {t('friends.raise_league')}
        </UIButton>
      )}

      {user.league && (
        <SuccessEnterLeagueModal
          visible={sucessModalOpen}
          onClose={() => setSucessModalOpen(false)}
          title={t(`friends.success_transfer_${user.league}`)}
          img={LEAGUE_VARIANTS[user.league].icon}
        />
      )}

      {user.league !== 'diamond' && (
        <NextLeagueConditionsModal
          visible={nextLeagueModalOpen}
          onClose={() => setNextLeagueModalOpen(false)}
          conditions={leagueInfo.requirements}
          title={leagueInfo.title}
          img={LEAGUE_VARIANTS[leagueInfo.kind].icon}
          buttonText={`${t('common.transfer_for')} ${formatLargeNumber(leagueInfo.price)} ${GAME_COIN_SHORTCUT}`}
          onSuccess={handleTransferNextLeague}
          price={leagueInfo.price}
        />
      )}
    </div>
  );
};
