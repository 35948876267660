import { SUPPORTED_LANGUAGES } from 'shared/constants';
import { AppUser, BotInfo, Faq } from 'shared/types';
import { createWithEqualityFn } from 'zustand/traditional';

type GlobalStore = {
  user: AppUser;
  globalLoading: boolean;
  appVisible: boolean;
  checkLimitLoading: boolean;
  refetchAppData: boolean;
  faq: Faq[];
  botInfo: BotInfo;
  setUser: (user: AppUser) => void;
  setGlobalLoading: (globalLoading: boolean) => void;
  addUserCoins: (coins: number) => void;
  updateUser: (data: Partial<AppUser>) => void;
  setAppVisibility: (appVisible: boolean) => void;
  setCheckLimitLoading: (checkLimitLoading: boolean) => void;
  toggleRefetchAppData: () => void;
  setFaq: (faq: Faq[]) => void;
  reduceUserBalance: (reduceAmount: number) => void;
  addUserTokens: (tokens: number) => void;
  increaseCurrentLimit: (amount: number) => void;
  setBotInfo: (botInfo: Partial<BotInfo>) => void;
};

export const useGlobalStore = createWithEqualityFn<GlobalStore>()((set) => ({
  user: {
    created_at: '',
    updated_at: '',
    uuid: '',
    tg_user_id: 0,
    username: '',
    first_name: '',
    last_name: '',
    tokens: 0,
    language: SUPPORTED_LANGUAGES.RU,
    finger_size: '39px',
    last_start: '',
    daily_limit: 0,
    wallet: '',
    league: 'bronze',
    coins: 0,
    has_bakery_access: false,
    current_limit: 0,
    daily_bonus_limit: 0,
  },
  globalLoading: true,
  appVisible: true,
  checkLimitLoading: true,
  refetchAppData: false,
  faq: [],
  botInfo: {
    lang: SUPPORTED_LANGUAGES.RU,
  },
  setUser: (user) => set(() => ({ user })),
  setGlobalLoading: (globalLoading) => set(() => ({ globalLoading })),
  addUserCoins: (coins: number) =>
    set((state) => ({ user: { ...state.user, coins: state.user.coins + coins } })),
  updateUser: (data) => set((state) => ({ user: { ...state.user, ...data } })),
  setAppVisibility: (appVisible) => set(() => ({ appVisible })),
  setCheckLimitLoading: (checkLimitLoading) => set(() => ({ checkLimitLoading })),
  toggleRefetchAppData: () => set((state) => ({ refetchAppData: !state.refetchAppData })),
  setFaq: (faq) => set(() => ({ faq })),
  reduceUserBalance: (reduceAmount) =>
    set((state) => ({ user: { ...state.user, coins: state.user.coins - reduceAmount } })),
  addUserTokens: (tokens: number) =>
    set((state) => ({ user: { ...state.user, tokens: state.user.tokens + tokens } })),
  increaseCurrentLimit: (amount) =>
    set((state) => ({ user: { ...state.user, current_limit: state.user.current_limit + amount } })),
  setBotInfo: (botInfo) => set((state) => ({ botInfo: { ...state.botInfo, ...botInfo } })),
}));
