import { GetRefsResponse, LeagueInfo, ReferralEntity } from 'shared/types';
import { create } from 'zustand';

type ReferralsStore = {
  totalRefs: number;
  referrals: ReferralEntity[];
  leagueInfo: LeagueInfo;
  setTotalRefs: (count: number) => void;
  setReferrals: (referrals: ReferralEntity[]) => void;
  setRefsStore: (data: Partial<GetRefsResponse>) => void;
  setLeagueInfo: (leagueInfo: LeagueInfo) => void;
};

export const useReferralsStore = create<ReferralsStore>((set) => ({
  totalRefs: 0,
  referrals: [],
  leagueInfo: { kind: 'bronze', price: 0, requirements: [], title: '' },
  setTotalRefs: (totalRefs) => set(() => ({ totalRefs })),
  setReferrals: (referrals) => set(() => ({ referrals })),
  setRefsStore: ({ count, data }) =>
    set((state) => ({
      totalRefs: count ? count : state.totalRefs,
      referrals: data ? data : state.referrals,
    })),
  setLeagueInfo: (leagueInfo) => set(() => ({ leagueInfo })),
}));
