import { useGameStore } from 'shared/store';
import styles from './style.module.scss';
import { useEffect } from 'react';
import { DeadFly } from 'components/lottie';

type FreezeOverlayProps = {
  freezeCooldown: number;
};

export const FreezeOverlay = ({ freezeCooldown }: FreezeOverlayProps) => {
  const frozen = useGameStore((state) => state.frozen);
  const setFrozen = useGameStore((state) => state.setFrozen);

  useEffect(() => {
    if (frozen) {
      const timeout = setTimeout(() => {
        setFrozen(false);
      }, freezeCooldown);

      return () => clearTimeout(timeout);
    }
  }, [frozen, freezeCooldown]);

  return (
    frozen && (
      <div className={styles['overlay']}>
        <DeadFly />
      </div>
    )
  );
};
