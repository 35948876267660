import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { finishSession, startSession } from 'shared/api';
import { ROUTES } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { calculateEarnedCurrency, handleErrorWithAlert, handleErrorStatus } from 'shared/utils';

export function useGame() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useGlobalStore((state) => state.user);
  const sessionId = useGameStore((state) => state.sessionId);
  const currentDonutsCount = useGameStore((state) => state.currentDonutsCount);
  const catchedBees = useGameStore((state) => state.catchedBees);
  const failedDonuts = useGameStore((state) => state.failedDonuts);
  const gameActive = useGameStore((state) => state.gameActive);
  const setSessionId = useGameStore((state) => state.setSessionId);
  const setGameActive = useGameStore((state) => state.setGameActive);
  const setFrozen = useGameStore((state) => state.setFrozen);
  const setGameReady = useGameStore((state) => state.setGameReady);
  const setCurrentDonutsCount = useGameStore((state) => state.setCurrentDonutsCount);
  const updateUser = useGlobalStore((state) => state.updateUser);
  const increaseCurrentLimit = useGlobalStore((state) => state.increaseCurrentLimit);

  const startGame = useCallback(async () => {
    try {
      setCurrentDonutsCount(0);
      const res = await startSession();
      setGameActive(true);
      setSessionId(res.data.uuid);
      updateUser({ last_start: res.data.last_start });
    } catch (error) {
      setGameActive(false);
      setGameReady(false);
      setSessionId('');
      setFrozen(false);
      navigate(ROUTES.MAIN, { replace: true });

      handleErrorWithAlert(error);
    }
  }, []);

  const leaveSession = (limitReached: boolean, coins: number) => {
    if (limitReached) {
      navigate(ROUTES.LIMIT_REACHED, {
        replace: true,
        state: { coins: coins },
      });
    } else {
      navigate(ROUTES.CONGRATULATIONS, {
        replace: true,
        state: { coins: coins },
      });
    }
  };

  const stopGame = useCallback(
    async (limitReached = false) => {
      if (!sessionId || gameActive) return;

      try {
        const earnedDonuts = calculateEarnedCurrency({
          dailyLimit: user.daily_limit + user.daily_bonus_limit,
          currentDonutsCount,
          currentLimit: user.current_limit,
          limitReached: limitReached,
        });

        const totalEarned = typeof earnedDonuts === 'number' && earnedDonuts > 0 ? earnedDonuts : 0;

        const res = await finishSession(sessionId, totalEarned, catchedBees, failedDonuts);
        increaseCurrentLimit(totalEarned);
        updateUser({ coins: res.data.total_coins });
        leaveSession(limitReached, earnedDonuts);
      } catch (error) {
        handleErrorStatus(error, 404, () => {
          alert(t('alerts.you_already_started_session'));
          navigate(ROUTES.MAIN, { replace: true });
        });
      } finally {
        setGameReady(false);
        setGameActive(false);
        setSessionId('');
        setFrozen(false);
      }
    },
    [catchedBees, failedDonuts, gameActive, currentDonutsCount],
  );

  useEffect(() => {
    startGame();
  }, []);

  return {
    startGame,
    stopGame,
  };
}
