import { UITypography } from 'shared/ui';
import styles from './style.module.scss';
import { useGameStore } from 'shared/store';

export const DonutCounter = () => {
  const currentDonutsCount = useGameStore((state) => state.currentDonutsCount);

  return (
    <div className={styles.counter}>
      <img src={'/donuts/blue-donut-29.png'} alt="" width={40} height={40} />
      <UITypography variant="Award">{currentDonutsCount}</UITypography>
    </div>
  );
};
