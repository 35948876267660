import styles from './style.module.scss';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DEFAULT_FINGER_SLIDER_CONFIG, ROUTES } from 'shared/constants';
import { DonutSize } from 'shared/types';
import { m, useAnimation } from 'framer-motion';
import { StarsMain } from 'components/lottie';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Scale } from 'shared/ui/animations';

export const UserSizeDonut = () => {
  const user = useGlobalStore((state) => state.user);
  const gameReady = useGameStore((state) => state.gameReady);
  const navigate = useNavigate();
  const controls = useAnimation();
  const [imgLoaded, setImgLoaded] = useState(false);

  const fingerSize = (parseInt(user.finger_size || '') ||
    DEFAULT_FINGER_SLIDER_CONFIG.min) as DonutSize;

  const onClick = () => {
    if (gameReady) {
      navigate(ROUTES.SESSION);
    }
  };

  useEffect(() => {
    if (gameReady) {
      controls.start(
        { scale: [1, 0.96, 1] },
        { duration: 2, repeat: Infinity, ease: 'easeInOut', delay: 0.15 },
      );
    }
  }, [gameReady]);

  return (
    <Scale start={imgLoaded}>
      <div className={styles.userSizeDonutRoot}>
        <div className={styles['blue-donut-container']}>
          <m.div
            className={styles['blue-donut-outer-ring']}
            initial={{ scale: 1 }}
            animate={controls}
            onClick={onClick}
          >
            <div className={styles['blue-donut-inner-ring']}>
              <img
                src={`/donuts/blue-donut-${fingerSize}.png` || '/donuts/blue-donut-44.png'}
                alt="blue-donut"
                className={styles['blue-donut']}
                width={120}
                height={120}
                onLoad={() => setImgLoaded(true)}
              />
              <div
                className={styles.donutCenter}
                style={{ width: fingerSize, height: fingerSize }}
              />
            </div>
          </m.div>
        </div>
        <div className={styles.starsContainer}>
          <StarsMain />
        </div>
      </div>
    </Scale>
  );
};
